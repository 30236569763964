import { Router } from '@reach/router';
import React from 'react';
import RedirectHome from '../components/redirect-home';

function SignIn(): JSX.Element {
  return (
    <Router>
      {/* Error from installing @types/react-helmet and @types/react-redux */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RedirectHome default={true} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
    </Router>
  );
}

SignIn.displayName = 'SignIn';

export default SignIn;
